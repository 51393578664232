import React from "react";

import LayoutJa from "../components/layout-ja";
import SEO from "../components/seo";
import Modal from "react-modal";
import { Link } from "gatsby";

import { openJoinUsPopup } from "../utils/utils";

const customStyles = {
  content: {
    width: "100%",
    maxWidth: 800,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 99999,
    border: "none",
  },
};

function IndexPage() {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <LayoutJa>
      <SEO
        keywords={[`ハイボット`, `HiBot`, `Tokyo`, `Robotics`]}
        title="Home"
      />

      <header className="header">
        <div className="container relative flex flex-col">
          <div className="text-right flex-1 flex flex-col items-end mt-48">
            <h1 className="text-5xl leading-none md:leading-tight font-bold mb-5 text-white">
              社会インフラ点検・維持管理<br></br>
              ソリューションをスマートに
            </h1>
            <div
              className="border-b-2 inline-block mb-5"
              style={{ width: 190 }}
            ></div>
            <p className="hidden md:block text-lg text-white mb-5 max-w-2xl">
              インフラ設備の安全性維持サービスの効率化のために、設備の劣化・損傷予測を自動化し、
              メンテナンス業務のための設備停止時間を軽減することが望まれています。
              ハイボット独自のスマートツールを組み合わせた HiBox
              は、オンサイトでの自律航法、
              データ収集・管理、リアルタイムのデータ可視化、AIベースの欠陥検出など、
              複数のメニューを統合したシームレスソリューションです。
            </p>
            <p>
              <a
                className="flex items-center btn bg-primary text-white inline-block px-5 py-3 shadow-md cursor-pointer"
                onClick={openModal}
              >
                <span className="icon-arrow-right--white mr-2"></span>
                <span>ビデオを見る</span>
              </a>
            </p>
          </div>
          <div className="hidden md:flex my-6 text-white uppercase font-mono">
            <div className="w-1/2">スマートな点検・維持管理</div>
            <div className="w-1/2 text-right">
              航空・石油/ガス/化学・原子力発電所
            </div>
          </div>
        </div>
      </header>
      <h2 className="text-center text-primary uppercase my-4">Partners</h2>
      <section>
        <div className="container">
          <div className="flex justify-center flex-wrap py-5">
            <div className="p-2">
              <img
                alt="Mirai Souzou"
                className="block"
                src={require("../images/clients/mirai.svg")}
                style={{ height: 65, width: "auto", marginRight: 10 }}
              />
            </div>
            <div className="p-2">
              <img
                alt="Fuyo Lease"
                className="block"
                src={require("../images/clients/fuyo.svg")}
                style={{
                  height: 40,
                  width: "auto",
                  marginRight: 10,
                  marginTop: 10,
                }}
              />
            </div>
            {/* <div className="p-2">
              <img
                alt="Tepco"
                className="block"
                src={require("../images/clients/tepco.svg")}
                style={{ height: 40, width: "auto", marginRight:14, marginTop:8 }}
              />
            </div> */}
            <div className="p-2">
              <img
                alt="Takahata"
                className="block"
                src={require("../images/clients/takahata.svg")}
                style={{
                  height: 30,
                  width: "auto",
                  marginRight: 14,
                  marginTop: 12,
                }}
              />
            </div>
            {/* <div className="p-2">
              <img
                alt="Tokyo Power Technology"
                className="block"
                src={require("../images/clients/tppt.svg")}
                style={{ height: 35, width: "auto", marginRight:14, marginTop:10 }}
              />
            </div> */}

            <div className="p-2">
              <img
                alt="Mitsubishi UFJ Capital"
                className="block"
                src={require("../images/clients/mucap.png")}
                style={{
                  height: 40,
                  width: "auto",
                  marginRight: 12,
                  marginTop: 10,
                }}
              />
            </div>
            <div className="p-2">
              <img
                alt="LiNKX"
                className="block"
                src={require("../images/clients/LiNKX_logo-01.png")}
                style={{
                  height: 50,
                  width: "auto",
                  marginRight: 12,
                  marginTop: 10,
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="border-b"></div>
      </div>

      <section id="news" className="py-10">
        <div className="container">
          <div className="md:flex mb-4">
            <div className="md:w-1/2 md:p-5">
              <h2 className="text-4xl font-bold mb-4 pl-4">News</h2>
              <p className="mb-4 border-l-4 border-gray-200 pl-4">
                <h3>
                  ハイボットが進化する。「Robot as a Service -
                  RaaS」として進化を遂げた新しいプラットフォームの誕生。
                </h3>
                みらい創造機構、芙蓉総合リース、タカハタプレシジョン、および東京パワーテクノロジーと資本業務提携
                ロボット&amp;AIデータプラットフォームによるメンテナンス事業を拡大
              </p>
              <p>
                <a onClick={openModal} className="cursor-pointer">
                  <span className="icon-arrow-right--primary mr-2 ml-6"></span>
                  ビデオを見る
                </a>
              </p>
            </div>
            <div className="md:w-1/2 md:p-5">
              <h2 className="text-xl mt-2 font-bold">プレスリリース</h2>
              <div className="flex flex-row border-b items-center">
                <div className="p-3">
                  <img
                    alt="PDF Icon"
                    className="inline-block"
                    src={require("../images/icons/icon-pdf.svg")}
                    style={{ width: 30, height: 37 }}
                  />
                </div>
                <div className="p-3 pl-0 text-gray-800">2020年4月16日</div>
                <div className="p-3 ml-auto">
                  {/* <a href="#" className="underline mr-1">
                    View
                  </a>{" "} */}
                  <a
                    href="https://www.hibot.co.jp/pdf/hibot_april2020_press02_jp.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    PDFをダウンロード
                  </a>
                </div>
              </div>
              <div className="flex flex-row border-b items-center">
                <div className="p-3">
                  <img
                    alt="PDF Icon"
                    className="inline-block"
                    src={require("../images/icons/icon-pdf.svg")}
                    style={{ width: 30, height: 37 }}
                  />
                </div>
                <div className="p-3 pl-0 text-gray-800">2020年3月26日</div>
                <div className="p-3 ml-auto">
                  <a
                    href="https://www.hibot.co.jp/pdf/hibot_march2020_press01_jp.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    PDFをダウンロード
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16" style={{ background: "#FAFAFA" }}>
        <div className="container">
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-5 leading-tight">
              ニュースレターに登録する
            </h2>
            <div>
              <form
                action="https://hibot.us19.list-manage.com/subscribe/post?u=f2bcfc4bf7904b85419f8cf48&amp;id=eb2bdd6bf0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
              >
                <div className="flex overflow-hidden bg-white border border-gray-400 max-w-lg mx-auto rounded-full h-12 shadow-sm">
                  <img
                    alt="E-mail Icon"
                    className="block ml-4 mr-3"
                    src={require("../images/icons/icon-email.svg")}
                    style={{ width: 30 }}
                  />
                  <input
                    placeholder="メールアドレス"
                    className="flex-1 outline-none bg-white"
                    type="email"
                    name="EMAIL"
                    required
                  />
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_f2bcfc4bf7904b85419f8cf48_eb2bdd6bf0"
                      tabIndex="-1"
                      value=""
                    />
                  </div>
                  <button className="hidden sm:block bg-primary text-white rounded-full px-6 -m-px outline-none">
                    登録する
                  </button>
                </div>
              </form>
              <button className="sm:hidden bg-primary text-white mt-2 rounded-full px-6 py-3 outline-none">
                Get informed
              </button>
            </div>
          </div>
        </div>
      </section>

      <h2 className="text-center text-primary uppercase my-12">Products</h2>

      <section id="products" className="py-10">
        <div className="container">
          <div className="md:flex">
            <div className="mb-5 md:mb-0 md:w-1/2 flex items-center">
              <img
                alt="Float Arm"
                className="block"
                src={require("../images/float-arm.jpg")}
              />
            </div>
            <div className="md:w-1/2 md:p-5">
              <div className="product-card">
                <h3 className="mb-6">
                  <img
                    alt="Float Arm"
                    className="block"
                    src={require("../images/float-arm-heading.svg")}
                    style={{ width: "85%" }}
                  />
                </h3>
                <p className="mb-8">
                  新ヘビ型マニピュレータFloatArmは狭隘スペースの遠隔作業用に開発されました。ヘビのように細長くしなやかな動作を可能にする独自技術のモーションコントロールにより、狭く見通しの悪い環境下でも高精度な検査を実施し、設備データを取得する
                  ことができます。 Float
                  Armは非常に速くてコンパクトで設置が容易です。
                  そのため、スタンドアローンデバイスとしてのご利用だけでなく、インフラ設備内で稼働中の
                  システムとの統合も容易であり、多様なアプリケーション環境に柔軟に対応する
                  ことができます。
                </p>
                <div className="flex justify-between mb-6">
                  <div className="flex-1 p-1 text-center">
                    <img
                      alt="Long Reach"
                      className="inline-block mb-1"
                      src={require("../images/icons/icon-reach.svg")}
                    />
                    <div className="text-xs md:text-sm uppercase text-primary">
                      Long Reach
                    </div>
                  </div>
                  <div className="flex-1 p-1 text-center">
                    <img
                      alt="Weight Compensation"
                      className="inline-block mb-1"
                      src={require("../images/icons/icon-weight.svg")}
                    />
                    <div className="text-xs md:text-sm uppercase text-primary">
                      Weight Compensation
                    </div>
                  </div>
                  <div className="flex-1 p-1 text-center">
                    <img
                      alt="Snake-Like Mobility"
                      className="inline-block mb-1"
                      src={require("../images/icons/icon-snake.svg")}
                    />
                    <div className="text-xs md:text-sm uppercase text-primary">
                      Snake-Like Mobility
                    </div>
                  </div>
                </div>
                <table className="features-table">
                  <tr>
                    <td>Extended length</td>
                    <td>5m</td>
                  </tr>
                  <tr>
                    <td>Mass</td>
                    <td>25kg</td>
                  </tr>
                  <tr>
                    <td>Payload</td>
                    <td>1.5kg</td>
                  </tr>
                  <tr>
                    <td>Number of links</td>
                    <td>8</td>
                  </tr>
                  <tr>
                    <td>Possible sensors</td>
                    <td>HD camera, Thermal camera, UT probe, LIDAR</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="border-b-2 border-primary block mx-auto"
        style={{ width: 220 }}
      ></div>

      <section className="py-10">
        <div className="container">
          <div className="md:flex flex-row-reverse">
            <div className="md:w-1/2">
              <img
                alt="Thesbot-Dual"
                className="block"
                src={require("../images/thesbot.jpg")}
              />
            </div>
            <div className="md:w-1/2 md:p-5">
              <div className="product-card">
                <div className="mb-4">
                  <h3 className="leading-none text-3xl text-gray-700 font-bold mb-0">
                    THESBOT-Dual
                  </h3>
                  <h4 className="text-primary font-bold">Pipe Inspection</h4>
                </div>
                <p className="text-gray-700 mb-8">
                  独自機構により、曲がり方向の異なる連続ベンドや、垂直管、溶接ビードのような
                  様々な配管環境において運用することができます。また、THESBOT-Dual
                  は目視検査に加えリモートフィールド、超音波探傷等の様々な装備を追加可能です。
                </p>
                <div className="flex justify-between mb-6">
                  <div className="flex-1 p-1 text-center">
                    <img
                      alt="Mobility in all directions"
                      className="inline-block mb-1"
                      src={require("../images/icons/icon-mobility.svg")}
                    />
                    <div className="text-xs md:text-sm uppercase text-primary">
                      Mobility in all directions
                    </div>
                  </div>
                  <div className="flex-1 p-1 text-center">
                    <img
                      alt="IPX7 water resistance"
                      className="inline-block mb-1"
                      src={require("../images/icons/icon-ipx7.svg")}
                    />
                    <div className="text-xs md:text-sm uppercase text-primary">
                      IPX7 water resistance
                    </div>
                  </div>
                  <div className="flex-1 p-1 text-center">
                    <img
                      alt="Real-time inspection"
                      className="inline-block mb-1"
                      src={require("../images/icons/icon-realtime.svg")}
                    />
                    <div className="text-xs md:text-sm uppercase text-primary">
                      Real-time inspection
                    </div>
                  </div>
                </div>
                <table className="features-table">
                  <tr>
                    <td>Pipe size</td>
                    <td>6&quot;</td>
                  </tr>
                  <tr>
                    <td>Length</td>
                    <td>757mm</td>
                  </tr>
                  <tr>
                    <td>Mass</td>
                    <td>6kg</td>
                  </tr>
                  <tr>
                    <td>Traction force</td>
                    <td>245N</td>
                  </tr>
                  <tr>
                    <td>Speed</td>
                    <td>100mm/s</td>
                  </tr>
                  <tr>
                    <td>Tether</td>
                    <td>50m (extendable to 100m)</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <h2 className="text-center text-primary uppercase mb-5">Services</h2>

      <section id="services" className="services-section py-16">
        <div className="container">
          <img
            alt="HiBox Logo"
            className="block mx-auto mb-10"
            src={require("../images/hibox.svg")}
            style={{ width: 150, height: 150 }}
          />
          <div className="md:flex mb-4">
            <div className="mb-5 md:mb-0 md:w-1/2 md:p-5">
              <h3 className="text-4xl mb-3 font-bold">HiBox - Smart MRO</h3>
              <p className="mb-4">
                インフラ設備の安全性維持サービスの効率化のために、設備の劣化・損傷予測を自動化し、
                メンテナンス業務のための設備停止時間を軽減することが望まれています。
                ハイボット独自のスマートツールを組み合わせた HiBox
                は、オンサイトでの自律航法、データ収集・管理、
                リアルタイムのデータ可視化、AIベースの欠陥検出など、複数のメニューを統合したシームレスソリューションです。
                HiBoxユーザーが設備状況の判断を行うために必要な機械学習アルゴリズムを用いたパターン認識や蓄積データ分析を、
                オフサイト環境で行い、設備管理者の設備劣化・損傷予測を支援いたします。
                HiBox
                はスマートツール安定稼働に欠かせないシステム健全性チェックやレポート自動作成機能を搭載しており、ユーザー様の業務効率化と生産性向上を実現します。
              </p>
              <p>
                <a href="#contact">お問い合わせはこちら</a>
              </p>
            </div>
            <div className="md:w-1/2 md:p-5">
              <div className="flex bg-white rounded-lg px-6 py-4 shadow-lg items-center mb-4">
                <img
                  alt="Autonomous Navigation"
                  className="mr-4"
                  src={require("../images/icons/icon-service-autonomous.svg")}
                  style={{ width: 50, height: 50 }}
                />
                <span className="uppercase text-gray-800">自律航法</span>
              </div>
              <div className="flex bg-white rounded-lg px-6 py-4 shadow-lg items-center mb-4">
                <img
                  alt="Inspection data comparison"
                  className="mr-4"
                  src={require("../images/icons/icon-service-data.svg")}
                  style={{ width: 50, height: 50 }}
                />
                <span className="uppercase text-gray-800">蓄積データ分析</span>
              </div>
              <div className="flex bg-white rounded-lg px-6 py-4 shadow-lg items-center mb-4">
                <img
                  alt="Robot health monitoring"
                  className="mr-4"
                  src={require("../images/icons/icon-service-monitoring.svg")}
                  style={{ width: 50, height: 50 }}
                />
                <span className="uppercase text-gray-800">
                  ロボットによる健全性モニタリング
                </span>
              </div>
              <div className="flex bg-white rounded-lg px-6 py-4 shadow-lg items-center mb-4">
                <img
                  alt="Autonomous generation of inspection reports"
                  className="mr-4"
                  src={require("../images/icons/icon-service-reports.svg")}
                  style={{ width: 50, height: 50 }}
                />
                <span className="uppercase text-gray-800">
                  検査レポートの自動作成
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-32">
        <div className="container text-center">
          <img
            alt="HiBot logo"
            className="inline-block mb-10"
            src={require("../images/hb-logo.svg")}
            style={{ width: 150, height: 150 }}
          />
          <h2 className="text-4xl font-bold mb-3 leading-none">
            チームの一員になりませんか？
          </h2>
          <p className="max-w-2xl mx-auto mb-10">
            ハイボットでは、社会インフラ検査・維持管理のための最先端ロボティクスプラットフォームの開発を担う才能あるエンジニアを募集しています。
          </p>
          <p>
            <button
              className="btn w-40 bg-primary inline-block text-white px-4 py-2 focus:outline-none"
              onClick={openJoinUsPopup}
            >
              エントリーする
            </button>
          </p>
        </div>
      </section>

      <section>
        <div className="container">
          <img
            alt="Story cover"
            className="inline-block mb-10"
            src={require("../images/story-cover.jpg")}
          />
        </div>
      </section>

      <section id="about" className="py-16">
        <div className="container">
          <div className="md:flex mb-4">
            <div className="md:w-1/2 md:p-5">
              <h2 className="text-4xl font-bold mb-4">Our story</h2>
              <p>
                ハイボットは2004年に、東京工業大学の広瀬茂男教授、ミケレ・グアラニエリ、パウロ・デベネストを中心とする創業メンバーによって、
                先端科学技術の産業応用を目的に設立されました。ハイボットは長年にわたり、「汚い」、「危険」、「きつい」といった極限環境におけるロボット技術の開発に特化してまいりました。
                危険な場所や人が行けない場所での作業をロボットへ代替する必要性を強く感じ、配管点検、社会インフラ産業インフラの維持管理、
                橋梁の劣化予測、原子力発電所、災害救助など数多くのソリューション提供しています。
              </p>
            </div>
            <div className="md:w-1/2 md:p-5">
              <div className="mt-10 timeline">
                <div className="flex timeline-item">
                  <div>
                    <div className="timeline-circle"></div>
                  </div>
                  <div>
                    <div className="text-gray-600">2004</div>
                    <div>
                      Establishment of HiBot at the Tokyo Institute of
                      Technology.
                    </div>
                  </div>
                </div>
                <div className="flex timeline-item">
                  <div>
                    <div className="timeline-circle"></div>
                  </div>
                  <div>
                    <div className="text-gray-600">2010</div>
                    <div>
                      Robot Award from the Japanese Ministry of Industry for
                      Project Expliner.
                    </div>
                  </div>
                </div>
                <div className="flex timeline-item">
                  <div>
                    <div className="timeline-circle"></div>
                  </div>
                  <div>
                    <div className="text-gray-600">2014</div>
                    <div>
                      Technology Pioneer recognition from the World Economic
                      Forum.
                    </div>
                  </div>
                </div>
                <div className="flex timeline-item">
                  <div>
                    <div className="timeline-circle"></div>
                  </div>
                  <div>
                    <div className="text-gray-600">2016</div>
                    <div>
                      CT-Arm successfully utilised inside Fukushima power plant
                      reactor 1.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16">
        <div className="container text-center">
          <h2 className="text-4xl leading-tight font-bold mb-0">チーム紹介</h2>
          <p className="mb-10">International from day one</p>
          <div className="md:flex justify-center flex-wrap">
            <div className="md:w-full p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/michele-guarnieri@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">ミケレ グアラニエリ</div>
              <div className="text-xs uppercase">共同創業者、CEO</div>
            </div>

            <div className="md:w-1/4 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/sato@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">佐藤 匠</div>
              <div className="text-xs uppercase">CFO</div>
            </div>

            <div className="md:w-1/4 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/kato@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">高山 朝邦</div>
              <div className="text-xs uppercase">社外取締役</div>
            </div>

            <div className="md:w-1/4 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/takayama@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">加藤 祐助</div>
              <div className="text-xs uppercase">監査役</div>
            </div>

            <div className="md:w-1/4 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/hirose@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">広瀬 茂男</div>
              <div className="text-xs uppercase">共同創業者、名誉技術顧問</div>
            </div>

            <div className="md:w-1/5 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/paulo@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">パウロ デベネスト</div>
              <div className="text-xs uppercase">
                共同創業者、執行役員 (プロジェクト管理)
              </div>
            </div>

            <div className="md:w-1/5 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/yamada@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">山田 浩也</div>
              <div className="text-xs uppercase">執行役員 (イノベーション)</div>
            </div>

            <div className="md:w-1/5 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/yonemura@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">米村 英彦</div>
              <div className="text-xs uppercase">執行役員 (製品化)</div>
            </div>

            <div className="md:w-1/5 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/kitano@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">北野 智士</div>
              <div className="text-xs uppercase">
                執行役員 (インパイプ ソリューション)
              </div>
            </div>

            <div className="md:w-1/5 p-5">
              <img
                alt=""
                className="inline-block mb-2"
                src={require("../images/members/azegami@2x.png")}
                style={{ width: 100, height: 100 }}
              />
              <div className="text-md font-bold">畔上 哲男</div>
              <div className="text-xs uppercase">執行役員 (営業)</div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="py-16">
        <div className="container">
          <div className="md:flex flex-row-reverse mb-4">
            <div className="md:w-1/2 md:p-10 mb-5 md:mb-0">
              <h2 className="text-4xl leading-tight font-bold mb-5">
                お問い合わせはこちら
              </h2>
              <p className="mb-10">
                <strong>ハイボット - 本社</strong>
              </p>
              <p className="mb-4">
                株式会社ハイボット
                <br />
                〒141-0001東京都
                <br />
                品川区北品川5-9-15
                <br />
                渡辺コーポレーションビル4階
                <br />
                <br />
                TEL: +81-(3)-5791-7526
                <br />
                FAX: +81-(3)-5791-7527
              </p>
              <p>
                <a href="#map" className="underline">
                  View on the map
                </a>
              </p>
            </div>
            <div className="md:w-1/2 md:p-5">
              <div className="w-120 flex shadow-lg flex-col bg-cover bg-center justify-content bg-white p-6 rounded pt-8 pb-8">
                <form
                  method="post"
                  action="https://getform.io/f/f3abf5cc-3d35-4948-9881-e5c983b34e21"
                >
                  <label>
                    <input
                      className="appearance-none bg-transparent border-b w-full text-gray-700 mr-3 mt-4 mb-8 py-1 px-2 leading-tight focus:outline-none "
                      placeholder="氏名"
                      aria-label="Full name"
                      type="text"
                      name="name"
                      id="name"
                    />
                    <input
                      className="appearance-none bg-transparent border-b w-full text-gray-700 mr-3 mb-8 py-1 px-2 leading-tight focus:outline-none "
                      placeholder="フリガナ"
                      aria-label="Full name"
                      type="text"
                      name="name"
                      id="name"
                    />
                  </label>
                  <label>
                    {/* Email */}
                    <input
                      className="appearance-none bg-transparent border-b w-full text-gray-700 mr-3 mb-8 py-1 px-2 leading-tight focus:outline-none "
                      placeholder="メールアドレス"
                      aria-label="Email"
                      type="email"
                      name="email"
                      id="email"
                    />
                  </label>
                  <label>
                    {/* Phone numnber */}
                    <input
                      className="appearance-none bg-transparent border-b w-full text-gray-700 mr-3 mb-8 py-1 px-2 leading-tight focus:outline-none "
                      placeholder="電話番号"
                      aria-label="tel"
                      type="tel"
                      name="te;"
                      id="tel"
                    />
                  </label>
                  <label>
                    {/* Message */}
                    <textarea
                      className="appearance-none bg-transparent border-b w-full text-gray-700 mr-3  py-1 px-2 leading-tight focus:outline-none"
                      placeholder="お問い合わせ内容"
                      name="message"
                      id="message"
                      rows="6"
                    />
                  </label>
                  <div className="flex mt-4">
                    <input
                      type="checkbox"
                      className="mr-2"
                      name="agreement"
                      value="agree"
                    />
                    <p className="text-gray-700">
                      同意する &nbsp;
                      <Link
                        to="/ja/privacy-policy/"
                        className="no-underline text-teal hover:text-teal-light"
                      >
                        プライバシーポリシー
                      </Link>
                    </p>
                  </div>
                  <button
                    className="btn bg-primary pt-3 pb-3 mt-0 w-full text-white hover:bg-teal-light rounded-full "
                    type="submit"
                  >
                    参加する
                  </button>
                  {/* <input type="reset" value="Clear" /> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12973.454265532098!2d139.7344174!3d35.6187627!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2eb205e9a80a57e1!2zSGlCb3Qg44OP44Kk44Oc44OD44OI!5e0!3m2!1sen!2sjp!4v1584587319659!5m2!1sen!2sjp"
          width="100%"
          height="600"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullscreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </section>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <button onClick={closeModal} className="uppercase mb-1 font-bold">
          close
        </button>

        <div className="aspect-ratio-16by9">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/6iB0YOhTY-I"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullscreen
            className="aspect-ratio-inside"
          ></iframe>
        </div>
      </Modal>
    </LayoutJa>
  );
}

export default IndexPage;
