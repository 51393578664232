import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "gatsby";

import { openJoinUsPopup } from "../utils/utils";

function LayoutJa({ children }) {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <div id="top">
      <nav className="navbar">
        <div className="container">
          <div className="flex items-center">
            <a href="/ja/">
              <img
                alt="HiBot logo"
                className="block w-40 mr-8"
                src={require("../images/hb-logo-full.svg")}
              />
            </a>
            <div className="hidden md:block">
              <Link to="/ja/#news" className="navbar-link">
                News
              </Link>
              <Link to="/ja/#products" className="navbar-link">
                Products
              </Link>
              <Link to="/ja/#services" className="navbar-link">
                Services
              </Link>
              <Link to="/ja/#about" className="navbar-link">
                About
              </Link>
              <Link to="/ja/#contact" className="navbar-link">
                Contact
              </Link>
            </div>
            <div className="ml-auto hidden lg:block">
              <Link to="/" className="navbar-link">
                English
              </Link>
              <button
                className="border-2 inline-block text-white border-white rounded-full px-8 py-3 uppercase focus:outline-none"
                onClick={openJoinUsPopup}
              >
                エントリー
              </button>
            </div>
            <div className="ml-auto md:hidden">
              <Link to="/" className="navbar-link">
                English
              </Link>
              <button
                className={`hamburger hamburger--slider ${
                  isExpanded ? "is-active" : ""
                }`}
                type="button"
                onClick={() => toggleExpansion(!isExpanded)}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </nav>
      <div
        className={`mobile-menu ${
          isExpanded ? "mobile-menu--open" : ""
        } bg-primary flex flex-col overflow-y-scroll`}
      >
        <div
          className="flex items-center flex-col m-auto"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <Link to="/ja/#news" className="mobile-menu-link">
            News
          </Link>
          <div className="mobile-menu-divider"></div>
          <Link to="/ja/#products" className="mobile-menu-link">
            Products
          </Link>
          <div className="mobile-menu-divider"></div>
          <Link to="/ja/#services" className="mobile-menu-link">
            Services
          </Link>
          <div className="mobile-menu-divider"></div>
          <Link to="/ja/#about" className="mobile-menu-link">
            About
          </Link>
          <div className="mobile-menu-divider"></div>
          <Link to="/ja/#contact" className="mobile-menu-link">
            Contact
          </Link>
        </div>
      </div>
      <main>{children}</main>
      <footer className="py-16 text-white" style={{ background: "#5C5C5C" }}>
        <div className="container">
          <img
            alt="HiBot logo"
            className="block mb-8"
            src={require("../images/hb-logo-full.svg")}
            style={{ width: 170 }}
          />
          <div className="md:flex">
            <div className="md:w-1/2">
              <div className="flex -m-3">
                <p className="p-3 text-white">
                  HiBot Watanabe Corp. Bldg. 4F Shinagawa-ku, Kita Shinagawa
                  5-9-15 141-0001 Tokyo, Japan
                </p>
                <p className="p-3 text-white">
                  株式会社ハイボット <br></br> 〒141-0001東京都
                  品川区北品川5-9-15 渡辺コーポレーションビル4階
                </p>
              </div>
            </div>
            <div className="md:w-1/2 mt-5">
              <div className="md:text-right -ml-3">
                <Link to="/ja/#news" className="p-3 text-white">
                  News
                </Link>
                <Link to="/ja/#products" className="p-3 text-white">
                  Products
                </Link>
                <Link to="/ja/#services" className="p-3 text-white">
                  Services
                </Link>
                <Link to="/ja/#about" className="p-3 text-white">
                  About
                </Link>
                |
                <Link to="/ja/privacy-policy/" className="p-3 text-white">
                  Privacy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <Link to="#top" className="backtop-btn">
        <img
          alt="Go to top"
          src={require("../images/icons/icon-backtop.svg")}
          style={{ width: 50, height: 50 }}
        />
      </Link>
    </div>
  );
}

LayoutJa.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutJa;
